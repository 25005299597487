import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import API from '../api';

const PER_PAGE_RECORDS = 20;
const useGetTransactionHistory = (tokenId) => {
  const called = useRef(false);

  const [tx, setTx] = useState({});
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  const doRefresh = useCallback(async () => {
    try {
      if (called.current) {
        return;
      }
      if (!tokenId) {
        return null;
      }
      called.current = true;
      setLoading(true);
      let url = `/marketplace/MarketPlaceTransactionHistory?where[tokenId]=${tokenId}&skip=${
        skip * PER_PAGE_RECORDS
      }&take=${PER_PAGE_RECORDS}&order[createDateTime]=DESC`;
      const response = await API.get(url);
      setTx(response);
    } catch (error) {
      toast.error(error?.error?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
      called.current = false;
    }
  }, [skip, tokenId]);

  useEffect(() => {
    doRefresh();
  }, [doRefresh, skip, tokenId]);

  return {
    tx,
    loading,
    setSkip,
    doRefresh,
    skip,
    keyword,
    setKeyword,
    PER_PAGE_RECORDS,
  };
};
export default useGetTransactionHistory;
