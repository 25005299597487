import React from "react";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/img/footer-logo.svg";
import routes from "../../constants/routesConstants";

const Footer = (props) => {
  return (
    <div
      className={`footer-wrap ${props.class !== undefined ? props.class : ""}`}
    >
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-auto col-12">
            <div className="footer-logo">
              <Link to={routes.Home}>
                <img src={footerLogo} alt="" />
              </Link>
              <span className="footer-copyright d-none d-sm-block">
                Copyright © {new Date().getFullYear()} CUBIX Pro
              </span>
            </div>
          </div>
          <div className="col-md-auto col-12">
            <div className="footer-links">
              <ul>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-auto col-12">
            <div className="footer-social-media">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/cubixpro.io/"
                    target="_blank"
                  >
                    <i className="icon-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://t.me/cubixpro" target="_blank">
                    <i className="icon-telegram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/cubix_world" target="_blank">
                    <i className="icon-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/cubixpro"
                    target="_blank"
                  >
                    <i className="icon-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 d-block d-sm-none">
            <span className="footer-copyright">
              Copyright © {new Date().getFullYear()} CUBIX Pro
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
