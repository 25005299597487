import { utils } from 'ethers';
import { getNMarketplaceContract } from '../constants/contractConstants';

export const convertToNumberFromBigNumber = (data) => {
  try {
    return parseInt(data?.toString(), 10);
  } catch (error) {
    return 0;
  }
};

export const formatEther = (balance) => {
  try {
    return utils.formatEther(String(balance));
  } catch (error) {
    return 0;
  }
};

export const getMarketplaceDetails = async (tokenId) => {
  try {
    if (process.env.REACT_APP_TRADE_STARTS !== 'true') {
      return {};
    }
    if (tokenId) {
      const marketplaceContract = await getNMarketplaceContract();

      const marketplace = await marketplaceContract.functions.marketplace(
        String(tokenId)
      );

      const marketplaceDetails = {
        auctionStarts: convertToNumberFromBigNumber(marketplace?.auctionStarts),
        auctionEnds: convertToNumberFromBigNumber(marketplace?.auctionEnds),
        id: convertToNumberFromBigNumber(marketplace?.id),
        isUSDT: marketplace.isUSDT,
        owner: marketplace.owner,
        perDayRent: marketplace?.perDayRent,
        price: marketplace?.price,
        tokenId: convertToNumberFromBigNumber(marketplace?.tokenId),
        typeOfSale: convertToNumberFromBigNumber(marketplace?.typeOfSale),
      };

      return marketplaceDetails;
    }
  } catch (error) {
    console.log({ error });
    return {};
  }
};

export const marketPlaceNFTStatus = {
  NOT_ON_SALE: 'NOT_ON_SALE',
  ON_SALE: 'ON_SALE',
  ON_AUCTION: 'ON_AUCTION',
  ON_RENT: 'ON_RENT',
  REMOVED: 'REMOVED',
};
export const marketPlaceNFTType = [
  marketPlaceNFTStatus?.NOT_ON_SALE,
  marketPlaceNFTStatus?.ON_SALE,
  marketPlaceNFTStatus?.ON_AUCTION,
  marketPlaceNFTStatus?.ON_RENT,
  marketPlaceNFTStatus?.REMOVED,
];
