import React from 'react';
import userImgLg from '../../assets/img/userLg.svg';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useGetUserNFTs from '../../hook/useGetUserNFTs';
import NFTCard from '../Shared/NFT/NFTCard';
import Pagination from '../Shared/Pagination/Pagination';
import useGetUserDetails from '../../hook/useGetUserDetails';
import CopyToClipboard from 'react-copy-to-clipboard';

import facebook from '../../assets/img/facebook.png';
import copy from '../../assets/img/copy.png';
import twitter from '../../assets/img/twitter.png';
import instagram from '../../assets/img/instagram.png';
import { toast } from 'react-toastify';
import useToggleUserFollow from '../../hook/useToggleUserFollow';
import useGetUserFollowers from '../../hook/useGetUserFollowers';

const UserDetails = () => {
  const params = useParams();
  const userName = params?.userName;

  const { collections, skip, setSkip, loading, PER_PAGE_RECORDS } =
    useGetUserNFTs(userName);

  const { details: userData } = useGetUserDetails(userName);
  const { followers, doRefresh } = useGetUserFollowers(userName);

  const { doToggle, loading: following } = useToggleUserFollow(doRefresh);

  const records = collections?.data?.records;
  const total = collections?.data?.total;
  const totalPages = Math.round(total / PER_PAGE_RECORDS);
  const totalPagesArray = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  return (
    <div className="container">
      <div className="profile-wrap-inner" style={{ overflowX: 'hidden' }}>
        <div className="profile-wrap-user">
          <div className="profile-wrap-image">
            <img src={userData?.profileImage || userImgLg} alt="" />
          </div>
          <div className="profile-wrap-user-info">
            <h5 className="profile-wrap-user-name">
              {userData?.userName ?? ''}
            </h5>
            <span className="profile-wrap-user-join">
              Joined {moment(userData?.createDateTime).format('MMM YYYY')}
            </span>
          </div>
          <p className="profile-wrap-wallet-id">{userData?.accountAddress}</p>

          <div>
            <div className="d-flex social-icons">
              {userData?.fbLink ? (
                <a target={'_blank'} href={userData?.fbLink}>
                  <img src={facebook} alt="facebook" />
                </a>
              ) : null}
              {userData?.twitterLink ? (
                <a target={'_blank'} href={userData?.twitterLink}>
                  <img src={twitter} alt="twitter" />
                </a>
              ) : null}
              {userData?.instagramLink ? (
                <a target={'_blank'} href={userData?.instagramLink}>
                  <img src={instagram} alt="linkedin" />
                </a>
              ) : null}
              <CopyToClipboard
                text={window.location.href}
                onCopy={() => {
                  toast.success('Link copied!');
                }}
              >
                <img src={copy} alt="copy" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="mt-4 d-flex">
            <div>
              <h5>{followers?.followings}</h5>
              Following
            </div>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <div>
              <h5>{followers?.followers}</h5>
              Followers
            </div>
          </div>
          <p>
            <div className="mt-4">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {
                  doToggle(userData?.id);
                }}
              >
                {following
                  ? 'Please wait'
                  : followers?.isFollowing
                  ? 'Following'
                  : 'Follow'}
              </button>
            </div>
          </p>
        </div>
        {records?.length <= 0 && !loading ? (
          <p className="mb-5 mt-5 text-center">
            You don't have any Cubix NFTs yet in your account.
          </p>
        ) : null}
        {loading ? <div className="loader">Loading...</div> : null}

        <div className="nft-cards">
          <div className="row">
            {records?.map((record) => {
              return <NFTCard record={record} />;
            })}
          </div>
        </div>
        <Pagination
          setSkip={setSkip}
          skip={skip}
          totalPagesArray={totalPagesArray}
        />
      </div>
    </div>
  );
};

export default UserDetails;
