import React, { useState } from 'react';

const Select = ({ options, onChange, placeholder = 'Select' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const openSelectHandler = () => {
    setIsOpen(!isOpen);
  };

  const optionSelector = (value, label) => {
    onChange(value);
    setSelectedValue(label);
    setIsOpen(false);
  };

  return (
    <div className="theme-select">
      <div
        className={`theme-select-selected ${isOpen ? 'open' : ''}`}
        onClick={() => openSelectHandler()}
      >
        {selectedValue === '' ? placeholder : selectedValue}
      </div>
      <ul className={`theme-select-option ${!isOpen ? 'd-none' : ''}`}>
        {options.map((option) => (
          <li
            key={option.value}
            onClick={() => optionSelector(option.value, option.label)}
            className={`${option.label === selectedValue ? 'selected' : ''}`}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Select;
