import React from 'react';
import useGetMarketplaceBids from '../../../../hook/useGetMarketplaceBids';
import { formatDate, formateTx } from '../../../../utils/dateUtils';
import {
  marketPlaceNFTStatus,
  marketPlaceNFTType,
} from '../../../../utils/etherConversionUtils';

const BidList = ({ marketplace, isOwner, doRefresh }) => {
  const typeOfSale = marketPlaceNFTType?.[marketplace?.typeOfSale];

  const { bids } = useGetMarketplaceBids(marketplace?.id, typeOfSale);

  const records = bids?.data?.records;
  //   const total = offers?.data?.total;

  if (typeOfSale !== marketPlaceNFTStatus.ON_AUCTION) {
    return null;
  }

  return (
    <div className="table-responsive nft-detail-history-card">
      <table className="offers table table-dark">
        <tr>
          <th className="text-center">Tx</th>
          <th className="text-center">Bidder</th>
          <th className="text-center">Bidder Address</th>
          <th className="text-center">Bid</th>
          <th className="text-center">Date</th>
        </tr>
        <tbody>
          {records?.map((r) => {
            return (
              <tr key={r?.id}>
                <td className="text-center">{formateTx(r?.txHash)}</td>
                <td className="text-center">{r?.bidderUser?.userName}</td>
                <td className="text-center">{r?.bidder}</td>
                <td className="text-center">{r?.price} CUBIX</td>
                <td className="text-center">{formatDate(r?.createDateTime)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <style global jsx>
        {`
          .offers td {
            vertical-align: middle;
          }
        `}
      </style>
    </div>
  );
};

export default BidList;
