import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Footer";
import NavbarTwo from "../Navbar/NavbarTwo";

const WrapperThree = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <NavbarTwo />
      <Outlet />
      <Footer class="is-blue" />
    </>
  );
};

export default WrapperThree;
