import { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import API from '../api';
import AuthContext from '../context/authContext';

const useSyncMyNFTs = (doRefresh) => {
  const userData = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const syncNFT = useCallback(
    async (nftId) => {
      if (!userData?.userData?.accountAddress && !nftId) {
        return;
      }
      try {
        setLoading(true);
        await API.post(`/user/sync-my-nfts`);
        toast.success('NFT sync successfully');
        doRefresh();
      } catch (error) {
        toast.warning(error?.message);
      } finally {
        setLoading(false);
      }
    },
    [userData?.userData?.accountAddress]
  );

  return { loading, syncNFT };
};
export default useSyncMyNFTs;
