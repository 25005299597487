import React, { useContext, useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import useUploadFile from '../../hook/useUploadFile';
import useUpdateProfile from '../../hook/useUpdateProfile';

import facebook from '../../assets/img/facebook.png';
import copy from '../../assets/img/copy.png';
import twitter from '../../assets/img/twitter.png';
import userImgLg from '../../assets/img/userLg.svg';
import AuthContext from '../../context/authContext';

const Profile = () => {
  const formRef = useRef();
  const [editable, setEditable] = useState(false);
  const { userData, login } = useContext(AuthContext);
  const { updateProfile } = useUpdateProfile(userData);
  const { updateUploadFile } = useUploadFile(userData);

  useEffect(() => {
    formRef?.current?.setFieldValue('userName', userData?.userName);
    formRef?.current?.setFieldValue('fullName', userData?.fullName);
    formRef?.current?.setFieldValue('email', userData?.email);
    formRef?.current?.setFieldValue('bio', userData?.bio);
    formRef?.current?.setFieldValue('fbLink', userData?.fbLink);
    formRef?.current?.setFieldValue('instagramLink', userData?.instagramLink);
    formRef?.current?.setFieldValue('twitterLink', userData?.twitterLink);
  }, [userData?.userName]);

  const profileLink = `${window.location.protocol}//${window.location.hostname}/profile/${userData?.userName}`;

  return (
    <div className="profile-wrap-inner">
      <div className="profile-wrap-user">
        <div className="profile-wrap-image">
          <img src={userData?.profileImage || userImgLg} alt="" />
        </div>
        <div className="profile-wrap-user-info">
          <h5 className="profile-wrap-user-name">{userData?.userName ?? ''}</h5>
          <span className="profile-wrap-user-join">
            Joined {moment(userData?.createDateTime).format('MMM YYYY')}
          </span>
        </div>
        <p className="profile-wrap-wallet-id">{userData?.accountAddress}</p>
        <div>
          <div className="d-flex social-icons">
            <a
              target={'_blank'}
              href={`https://www.facebook.com/sharer.php?u=${profileLink}`}
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              target={'_blank'}
              href={`https://twitter.com/intent/tweet?url=${profileLink}`}
            >
              <img src={twitter} alt="twitter" />
            </a>
            {/* <a
              target={'_blank'}
              href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${profileLink}`}
            >
              <img src={linkedin} alt="linkedin" />
            </a> */}
            <CopyToClipboard
              text={profileLink}
              onCopy={() => {
                toast.success('Profile link copied!');
              }}
            >
              <img src={copy} alt="copy" />
            </CopyToClipboard>
          </div>
        </div>
      </div>
      <Formik
        innerRef={formRef}
        initialValues={{
          userName: '',
          profileImage: '',
          file: null,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (!editable) {
            setEditable(true);
            return;
          }
          setSubmitting(true);
          if (values?.file) {
            const imageResponse = await updateUploadFile(values?.file);
            values.profileImage = imageResponse?.data?.url;
            window.document.getElementById('file').value = '';
          }
          if (values.profileImage === '') {
            values.profileImage = undefined;
          }
          await updateProfile(values);
          login({
            accountAddress: userData?.accountAddress,
          });
          setSubmitting(false);
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-12 mb-3">
                    <label>Select profile picture</label>
                    <input
                      id="file"
                      className="form-control"
                      placeholder="Select file"
                      type="file"
                      name="file"
                      value={values.file?.filename}
                      onChange={(e) =>
                        setFieldValue('file', e.target.files?.[0])
                      }
                      readOnly={!editable}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <label>Username</label>
                    <input
                      className="form-control"
                      placeholder="Enter username"
                      value={values.userName}
                      name="userName"
                      onChange={handleChange}
                      readOnly={!editable}
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label>Full name</label>
                    <input
                      className="form-control"
                      placeholder="Enter full name"
                      value={values.fullName}
                      name="fullName"
                      onChange={handleChange}
                      readOnly={!editable}
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label>Email</label>
                    <input
                      className="form-control"
                      placeholder="Enter email"
                      value={values.email}
                      name="email"
                      onChange={handleChange}
                      readOnly={!editable}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <label>Bio</label>
                    <textarea
                      className="form-control"
                      placeholder="Enter bio"
                      value={values.bio}
                      name="bio"
                      onChange={handleChange}
                      readOnly={!editable}
                      rows={3}
                    >
                      {values.bio}
                    </textarea>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label>Facebook Link</label>
                    <input
                      className="form-control"
                      placeholder="Enter FB link"
                      value={values.fbLink}
                      name="fbLink"
                      onChange={handleChange}
                      readOnly={!editable}
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label>Instagram Link</label>
                    <input
                      className="form-control"
                      placeholder="Enter Instagram link"
                      value={values.instagramLink}
                      name="instagramLink"
                      onChange={handleChange}
                      readOnly={!editable}
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label>Twitter Link</label>
                    <input
                      className="form-control"
                      placeholder="Enter twitter link"
                      value={values.twitterLink}
                      name="twitterLink"
                      onChange={handleChange}
                      readOnly={!editable}
                    />
                  </div>
                </div>
              </div>
              <div className="nft-cards-view-more mb-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {editable ? 'Update Details' : 'Edit Profile'}
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default Profile;
