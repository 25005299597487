import React from 'react';
import useGetTransactionHistory from '../../../../hook/useGetTransactionHistory';
import { formatDate } from '../../../../utils/dateUtils';

const TransactionHistory = ({ tokenId }) => {
  const { tx } = useGetTransactionHistory(tokenId);

  const records = tx?.data?.records;

  return (
    <div className="col-lg-8">
      <div className="nft-detail-history-card">
        <h3 className="nft-detail-history-title">Transaction History</h3>
        <div className="nft-detail-history-list">
          <ul>
            {records?.map((tx) => {
              return (
                <li key={tx?.id}>
                  <span className="nft-detail-history-list-text flex-lg-fill">
                    <div className="nft-detail-history-list-icon">
                      <i className="icon-transaction"></i>
                    </div>
                    {tx?.description}
                  </span>
                  <span className="nft-detail-history-list-time">
                    {formatDate(tx?.createDateTime)}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <style jx global>
        {`
            .nft-detail-history-list {
                height: 200px;
                overflow: hidden;
                overflow-y: auto;
            }

            `}
      </style>
    </div>
  );
};

export default TransactionHistory;
