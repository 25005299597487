import moment from 'moment';

export const formatDate = (date) => {
  return moment(date).format('DD-MM-YYYY HH:mm a');
};

export const formateTx = (tx) => {
  return (
    <a href={`https://polygonscan.com/tx/${tx}`} target="blank">
      {String(tx).substring(0, 4)}...{String(tx).substring(-1, 4)}
    </a>
  );
};
