import AuthContext from '../context/authContext';
import { toast } from 'react-toastify';
import { getNMarketplaceContract } from '../constants/contractConstants';
import useApproveCubix from './useApproveCubix';
import { getMarketplaceDetails } from '../utils/etherConversionUtils';

const { useState, useContext } = require('react');

const useGetNFTOnRent = (doRefresh) => {
  const { userData, logout } = useContext(AuthContext);
  const { approve, loading: approving, checkAllowance } = useApproveCubix();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setError();
    setLoading(false);
  };

  const rentNftCall = async (marketplaceContract, tokenId, days) => {
    return new Promise(async (resolve) => {
      const rentedNFT = await marketplaceContract.functions.getNFTOnRent(
        tokenId,
        days
      );
      rentedNFT.wait(3).then(() => {
        toast.success(
          `${tokenId} NFT rented successfully, will be reflected in 1 minute in your console`
        );
        resolve(true);
        doRefresh(false);
      });
    });
  };

  const rentNFT = async (tokenId, days) => {
    try {
      if (process.env.REACT_APP_TRADE_STARTS !== 'true') {
        throw new Error('Trade not started');
      }
      const allowance = await checkAllowance();
      reset();
      setLoading(true);
      const marketplaceContract = await getNMarketplaceContract();

      const marketplaceDetails = await getMarketplaceDetails(tokenId);
      const priceToConsider = marketplaceDetails?.price * days;
      // todo also check for isUSDT flag
      if (allowance < priceToConsider) {
        // call approve and wait for tx confirmed
        const approved = await approve(String(priceToConsider));
        if (approved === true) {
          await rentNftCall(marketplaceContract, tokenId, days);
        }
      } else {
        // call buy NFT method of marketplace directly
        await rentNftCall(marketplaceContract, tokenId, days);
      }
    } catch (error) {
      setError(error);
      console.log({ error });
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    rentNFT,
    reset,
    userData,
    error,
    loading: loading || approving,
    logout,
  };
};

export default useGetNFTOnRent;
