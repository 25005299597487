import moment from 'moment/moment';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import API from '../api';
import AuthContext from '../context/authContext';

let PER_PAGE_RECORDS = 21;
const useGetMyCollection = (perPageRecords, preventScroll) => {
  PER_PAGE_RECORDS = perPageRecords ?? 21;
  const userData = useContext(AuthContext);

  const called = useRef(false);
  const [collections, setCollections] = useState({});
  const [keyword, setKeyword] = useState('');
  const [stacked, setStacked] = useState('');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  const doRefresh = useCallback(async () => {
    if (called.current) {
      return;
    }
    if (!userData?.userData?.accountAddress) {
      return;
    }
    if (!preventScroll) {
      window?.scroll(0, 0);
    }
    try {
      called.current = true;
      setLoading(true);
      let url = `/user/nfts?where[currentOwner]=${
        userData?.userData?.accountAddress
      }&relations[]=user&skip=${
        skip * PER_PAGE_RECORDS
      }&take=${PER_PAGE_RECORDS}&relations[]=staked`;
      if (keyword !== '') {
        url += `&extra[keyword]=${keyword}`;
      }
      if (stacked !== '') {
        url += `&extra[stacked]=${stacked}`;
      }
      const response = await API.get(url);
      setCollections({
        ...response,
        data: {
          ...response?.data,
          records: response?.data?.records?.map((r) => {
            // checked where stacked or not
            const stacked = r?.staked?.some((s) => {
              return moment().isBetween(moment(s?.stackedOn), moment(s?.ends));
            });

            const canUnstack = r?.staked?.some((s) => {
              return moment().isSameOrAfter(moment(s?.ends));
            });

            return {
              ...r,
              isInStacking: stacked,
              canUnstack,
            };
          }),
        },
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
      called.current = false;
    }
  }, [skip, userData?.userData?.accountAddress, keyword, stacked]);

  useEffect(() => {
    doRefresh();
  }, [doRefresh, skip, userData?.userData?.accountAddress, keyword]);

  return {
    collections,
    loading,
    setSkip,
    doRefresh,
    skip,
    keyword,
    setKeyword,
    PER_PAGE_RECORDS,
    search,
    setSearch,
    setStacked,
    stacked,
  };
};
export default useGetMyCollection;
