import { useCallback, useEffect, useState } from 'react';
import API from '../api';

const useGetUserDetails = (userName) => {
  const [details, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const doRefresh = useCallback(async () => {
    try {
      setLoading(true);
      let url = `/user/details/${userName}`;
      const response = await API.get(url);
      setUserDetails(response?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [userName]);

  useEffect(() => {
    doRefresh();
  }, [doRefresh, userName]);

  return {
    details,
    loading,
    doRefresh,
  };
};
export default useGetUserDetails;
