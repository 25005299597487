import React from 'react';
import useAcceptOfferOnSale from '../../../../hook/useAcceptOfferOnSale';
import useGetMarketplaceOffers from '../../../../hook/useGetMarketplaceOffers';
import { formatDate, formateTx } from '../../../../utils/dateUtils';
import {
  marketPlaceNFTStatus,
  marketPlaceNFTType,
} from '../../../../utils/etherConversionUtils';

const OfferList = ({ marketplace, isOwner, doRefresh }) => {
  const typeOfSale = marketPlaceNFTType?.[marketplace?.typeOfSale];

  const { offers, loading } = useGetMarketplaceOffers(
    marketplace?.id,
    typeOfSale
  );

  const { acceptOfferOnSale, loading: acceptingOffer } =
    useAcceptOfferOnSale(doRefresh);
  const records = offers?.data?.records;
  //   const total = offers?.data?.total;

  if (typeOfSale !== marketPlaceNFTStatus.ON_SALE) {
    return null;
  }
  return (
    <div className="table-responsive nft-detail-history-card">
      <table className="offers table table-dark">
        <tr>
          <th className="text-center">Tx</th>
          <th className="text-center">Buyer</th>
          <th className="text-center">Buyer Address</th>
          <th className="text-center">Offer</th>
          <th className="text-center">Date</th>
          <th className="text-center">Action</th>
        </tr>
        <tbody>
          {loading ? (
            <tr>
              <td className="text-center" colSpan={5}>
                Loading...
              </td>
            </tr>
          ) : null}
          {!loading && records?.length <= 0 ? (
            <tr>
              <td className="text-center" colSpan={5}>
                No Offers Found
              </td>
            </tr>
          ) : null}
          {records?.map((r) => {
            return (
              <tr>
                <td className="text-center">{formateTx(r?.txHash)}</td>
                <td className="text-center">{r?.buyerUser?.userName}</td>
                <td className="text-center">{r?.buyer}</td>
                <td className="text-center">{r?.price} CUBIX</td>
                <td className="text-center">{formatDate(r?.createDateTime)}</td>
                <td className="text-center">
                  {isOwner ? (
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => {
                        if (!acceptingOffer) {
                          acceptOfferOnSale(
                            marketplace?.tokenId,
                            r?.buyer,
                            marketplace?.id
                          );
                        }
                      }}
                    >
                      {acceptingOffer ? 'Please wait' : 'Accept Offer'}
                    </button>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <style global jsx>
        {`
          .offers td {
            vertical-align: middle;
          }
        `}
      </style>
    </div>
  );
};

export default OfferList;
