import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import API from '../api';
import routes from '../constants/routesConstants';

const PER_PAGE_RECORDS = 20;
const useGetMarketplaceNFTs = () => {
  const navigation = useNavigate();

  const location = useLocation();
  const query = new URLSearchParams(location?.search);

  const _country = query.get('country');
  const _category = query.get('category');

  const called = useRef(null);
  const [nfts, setNfts] = useState({});
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [country, setCountry] = useState(_country);
  const [category, setCategory] = useState(_category);

  const reset = () => {
    setNfts({});
    setSkip(0);
  };

  const doRefresh = useCallback(async (skip, sortKey, category, country) => {
    try {
      let order;
      if (!sortKey || sortKey === 'all') {
        order = undefined;
      } else {
        const [key, orderBy] = sortKey?.split('-');
        order = {
          key,
          order: orderBy,
        };
      }

      setLoading(true);
      let url = `/marketplace/MarketPlace?relations[]=nftDetails&relations[]=ownerUser&&activeMarketplace=1&where[isActive]=true&skip=${
        skip * PER_PAGE_RECORDS
      }&take=${PER_PAGE_RECORDS}`;

      if (order) {
        url = `${url}&order[${order.key}]=${order?.order}`;
      }

      if (keyword) {
        url = `${url}&where[keyword][tokenId]=${keyword}`;
      }

      if (category) {
        url = `${url}&where[nftDetails][category]=${category}`;
      }

      if (country) {
        url = `${url}&where[nftDetails][[country]=${country}`;
      }

      const response = await API.get(url);
      if (response?.data?.records?.length > 0) {
        if (nfts?.data?.records) {
          setNfts({
            ...response,
            data: {
              records: [...nfts?.data?.records, ...response?.data?.records],
            },
          });
        } else {
          setNfts(response);
        }
        setSkip(skip);
      } else {
        if (skip !== 0) {
          toast.warning('All marketplace NFTs are fetched.');
        }
      }
    } catch (error) {
      toast.error(error?.error?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!called.current) {
      called.current = true;
      doRefresh(skip);
    }
  }, [doRefresh]);

  useEffect(() => {
    let url = `${routes.Marketplace}`;
    let search = '?';
    if (category) {
      search = `${search}category=${category}`;
    }
    if (country) {
      search = `${search}&country=${country}`;
    }

    navigation({
      pathname: url,
      search,
    });

    reset();
    doRefresh(0, undefined, category, country);
  }, [country, category]);
  return {
    nfts,
    loading,
    setSkip,
    doRefresh,
    skip,
    keyword,
    setKeyword,
    PER_PAGE_RECORDS,
    called,
    reset,
    setCountry,
    setCategory,
    category,
    country,
  };
};
export default useGetMarketplaceNFTs;
