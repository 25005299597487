import React, { useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import logoImg from '../../assets/img/logo.svg';
import userImg from '../../assets/img/user.svg';
import routes from '../../constants/routesConstants';
import AuthContext from '../../context/authContext';

const Sidebar = () => {
  const { userData, logout } = useContext(AuthContext);

  return (
    <div className="theme-sidebar">
      <div className="theme-logo">
        <Link to={routes.Marketplace}>
          <img src={logoImg} alt="" />
        </Link>
      </div>
      <div className="theme-sidebar-user">
        <div className="theme-sidebar-user-info">
          <div className="theme-sidebar-user-image">
            <img src={userData?.profileImage || userImg} alt="" />
          </div>
          <span className="theme-sidebar-user-name">
            {String(userData?.userName ?? userData?.accountAddress).slice(
              0,
              12
            )}
          </span>
        </div>
        <ul className="theme-sidebar-user-links">
          <li>
            <NavLink to={routes.Profile}>
              <i className="icon-profile"></i> Profile
            </NavLink>
          </li>
          <li>
            <NavLink to={routes.Favourites}>
              <i className="icon-favourites"></i> Favourites
            </NavLink>
          </li>
          {/* <li>
            <a href="#">
              <i className="icon-activity"></i> Activity
            </a>
          </li> */}
          <li>
            <NavLink to={routes.Collection}>
              <i className="icon-collection"></i> My Collections
            </NavLink>
          </li>
          <li>
            <NavLink to={routes.Marketplace}>
              <i className="icon-marketplace"></i> Marketplace
            </NavLink>
          </li>
          <li>
            <NavLink to={routes.StakingRewards}>
              <i className="icon-marketplace"></i> Airdrop
            </NavLink>
          </li>
          <li>
            <a href="javascript:void(0)" onClick={() => logout()}>
              <i className="icon-logout"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
