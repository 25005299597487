import React, { useState } from 'react';
import Select from '../Select';
import useGetMyCollection from '../../hook/useGetMyCollection';
import NFTCard from '../Shared/NFT/NFTCard';
import useSyncMyNFTs from '../../hook/useSyncMyNFTs';
import Pagination from '../Shared/Pagination/Pagination';

const Collection = () => {
  const {
    collections,
    skip,
    setSkip,
    loading,
    doRefresh,
    // keyword,
    setKeyword,
    PER_PAGE_RECORDS,
    search,
    setSearch,
  } = useGetMyCollection();
  const { syncNFT, loading: syncing } = useSyncMyNFTs(doRefresh);

  const records = collections?.data?.records;
  const total = collections?.data?.total;
  const totalPages = Math.round(total / PER_PAGE_RECORDS);
  const totalPagesArray = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  const [selectedValue, setSelectedValue] = useState(null);

  return (
    <div className="profile-wrap-inner">
      <div className="profile-wrap-header">
        <h1 className="profile-wrap-title">My Collections</h1>
        <div className="float-end">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => {
              syncNFT();
            }}
          >
            {syncing ? 'Syncing...' : 'Sync NFTs'}
          </button>
        </div>
      </div>
      <div className="home-listing-filters">
        <div className="row">
          <div className="col-sm-12">
            <div className="home-listing-search">
              <input
                type="text"
                className="form-control"
                placeholder="Search by Token ID, Name, Category, Country Code"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  if (e.target.value === '') {
                    setKeyword('');
                  }
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    setKeyword(search);
                  }
                }}
              />
            </div>
          </div>
          {false ? (
            <div className="col-sm-4">
              <div className="home-listing-sorting">
                <Select
                  options={[
                    { value: 'all', label: 'All' },
                    { value: 'buy', label: 'Buy' },
                    { value: 'rent', label: 'Rent' },
                    { value: 'bid', label: 'Bid' },
                    {
                      value: 'rarity-high',
                      label: 'Rarity: High to Low',
                    },
                    {
                      value: 'ranking-low',
                      label: 'Ranking: Low to High',
                    },
                    { value: 'price-low', label: 'Price: Low to High' },
                  ]}
                  onChange={setSelectedValue}
                  placeholder="Sort"
                  value={selectedValue}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {records?.length <= 0 && !loading ? (
        <p className="mb-5 mt-5 text-center">
          You don't have any Cubix NFTs yet in your account, Please click on
          Sync NFTs.
        </p>
      ) : null}
      {loading ? <div className="loader">Loading...</div> : null}

      <div className="nft-cards">
        <div className="row">
          {records?.map((record) => {
            return <NFTCard record={record} />;
          })}
        </div>
      </div>
      <Pagination
        setSkip={setSkip}
        skip={skip}
        totalPagesArray={totalPagesArray}
      />
    </div>
  );
};

export default Collection;
