import AuthContext from '../context/authContext';
import { toast } from 'react-toastify';
import { getNMarketplaceContract } from '../constants/contractConstants';
import useApproveCubix from './useApproveCubix';
import { ethers } from 'ethers';
import { formatEther } from '../utils/etherConversionUtils';

const { useState, useContext } = require('react');

const usePutOfferOnSale = (doRefresh) => {
  const { userData, logout } = useContext(AuthContext);
  const { approve, loading: approving, checkAllowance } = useApproveCubix();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setError();
    setLoading(false);
  };

  const placeOffer = (tokenId, _marketPlaceId, price, marketplaceContract) => {
    return new Promise(async (resolve) => {
      const offerPlaced = await marketplaceContract.functions.putOfferOnSale(
        _marketPlaceId,
        tokenId,
        ethers.utils.parseUnits(String(price), 18)
      );

      offerPlaced.wait(3).then(() => {
        toast.success(
          `${tokenId} Offer added for NFT successfully, will be reflected in 1 minute in your console`
        );
        setLoading(false);
        resolve(true);
        doRefresh?.(false);
      });
    });
  };

  const putOfferOnSale = async (_marketPlaceId, tokenId, price) => {
    try {
      console.log({ price });
      if (process.env.REACT_APP_TRADE_STARTS !== 'true') {
        throw new Error('Trade not started');
      }
      if (price <= 0) {
        toast.error('Please enter offer price.');
        return;
      }
      const allowance = await checkAllowance();

      reset();
      setLoading(true);
      const marketplaceContract = await getNMarketplaceContract();

      if (parseFloat(formatEther(allowance), 10) < price) {
        // call approve and wait for tx confirmed
        const approved = await approve(String(price));
        if (approved === true) {
          await placeOffer(tokenId, _marketPlaceId, price, marketplaceContract);
        }
      } else {
        // call method of marketplace directly
        await placeOffer(tokenId, _marketPlaceId, price, marketplaceContract);
      }

      // check approve
    } catch (error) {
      setError(error);
      console.log({ error });
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    putOfferOnSale,
    reset,
    userData,
    error,
    loading,
    logout,
    approving,
  };
};

export default usePutOfferOnSale;
