import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import API from '../api';
import { marketPlaceNFTStatus } from '../utils/etherConversionUtils';

const PER_PAGE_RECORDS = 20;
const useGetMarketplaceBids = (marketPlaceId, typeOfSale) => {
  const [bids, setBids] = useState({});
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  const doRefresh = useCallback(async () => {
    try {
      if (typeOfSale !== marketPlaceNFTStatus.ON_AUCTION) {
        return;
      }
      setLoading(true);
      let url = `/marketplace/MarketplaceNftAuctionBidsRecords?relations[]=bidderUser&where[_id]=${marketPlaceId}&skip=${
        skip * PER_PAGE_RECORDS
      }&take=${PER_PAGE_RECORDS}`;
      const response = await API.get(url);
      setBids(response);
    } catch (error) {
      toast.error(error?.error?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  }, [skip, marketPlaceId]);

  useEffect(() => {
    doRefresh();
  }, [doRefresh, skip, marketPlaceId]);

  return {
    bids,
    loading,
    setSkip,
    doRefresh,
    skip,
    keyword,
    setKeyword,
    PER_PAGE_RECORDS,
  };
};
export default useGetMarketplaceBids;
