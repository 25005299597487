const routes = {
  Home: '/',
  Nft: '/nft/:id',
  Profile: '/profile',
  Favourites: '/favourites',
  Collection: '/collection',
  UserDetails: '/profile/:userName',
  Marketplace: '/marketplace',
  Staking: '/staking',
  StakingRewards: '/airdrop',
  AndroidAppDownload: 'https://cubixpro.world/cubix_fantasy_sports.apk'
};
export default routes;
