import AuthContext from '../context/authContext';
import { toast } from 'react-toastify';
import {
  CONTRACT_ADDRESS,
  CUBIX_TOKEN_ABI,
  USDT_TOKEN_ABI,
} from '../constants/contractConstants';
import { convertToNumberFromBigNumber } from '../utils/etherConversionUtils';

const ethers = require('ethers');
const { useState, useContext } = require('react');

const useApproveCubix = () => {
  const { userData, logout } = useContext(AuthContext);

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setError();
    setLoading(false);
  };

  const checkAllowance = async () => {
    try {
      if (process.env.REACT_APP_TRADE_STARTS !== 'true') {
        return;
      }
      setLoading(true);

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
      const tokenContract = new ethers.Contract(
        CONTRACT_ADDRESS.cubixToken,
        CUBIX_TOKEN_ABI,
        provider
      ).connect(provider);
      const allowance = await tokenContract.functions.allowance(
        userData?.accountAddress,
        CONTRACT_ADDRESS.marketplace
      );
      setLoading(false);
      return convertToNumberFromBigNumber(allowance);
    } catch (error) {
      setLoading(false);
    }
  };

  const approve = (amount) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (process.env.REACT_APP_TRADE_STARTS !== 'true') {
          throw new Error('Trade not started');
        }
        reset();
        setLoading(true);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send('eth_requestAccounts', []);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(
          CONTRACT_ADDRESS.cubixToken,
          CUBIX_TOKEN_ABI,
          signer
        ).connect(signer);

        const approved = await tokenContract.functions.approve(
          CONTRACT_ADDRESS.marketplace,
          ethers.utils.parseUnits(String(amount), 18)
        );

        approved.wait(3).then(() => {
          toast.success(`${amount} token approved, now you can proceed ahead.`);
          resolve(true);
          setLoading(false);
        });

        // check approve
      } catch (error) {
        setError(error);
        reject(error);
        toast.error(error?.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    });
  };

  return { approve, reset, userData, error, loading, logout, checkAllowance };
};

export default useApproveCubix;
