import AuthContext from '../context/authContext';
import { toast } from 'react-toastify';
import { getNMarketplaceContract } from '../constants/contractConstants';

const { useState, useContext } = require('react');

const useSetWinnerForAuction = (doRefresh) => {
  const { userData, logout } = useContext(AuthContext);

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setError();
    setLoading(false);
  };

  const setWinner = (tokenId, _marketPlaceId) => {
    return new Promise(async (resolve) => {
      try {
        if (process.env.REACT_APP_TRADE_STARTS !== 'true') {
          resolve();
        }
        reset();
        setLoading(true);
        const marketplaceContract = await getNMarketplaceContract();

        const acceptedOffer =
          await marketplaceContract.functions.setWinnerForAuction(
            tokenId,
            _marketPlaceId
          );

        acceptedOffer.wait(3).then(() => {
          toast.success(
            `${tokenId} set winner for NFT successfully, will be reflected in 1 minute in your console`
          );
          setLoading(false);
          resolve(true);
          doRefresh?.(false);
          setLoading(false);
        });

        // check approve
      } catch (error) {
        setError(error);
        console.log({ error });
        toast.error(error?.message);
        setLoading(false);
      }
    });
  };

  return { setWinner, reset, userData, error, loading, logout };
};

export default useSetWinnerForAuction;
