import { useCallback, useContext, useEffect, useState } from 'react';
import API from '../api';
import AuthContext from '../context/authContext';

const useGetUserFollowers = (userName) => {
  const { userData } = useContext(AuthContext);
  const [followers, setFollowers] = useState({});
  const [loading, setLoading] = useState(false);

  const doRefresh = useCallback(async () => {
    try {
      setLoading(true);
      let url = `/user/followers/${userName}?userId=${userData?.id}`;
      const response = await API.get(url);
      setFollowers(response?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [userName]);

  useEffect(() => {
    doRefresh();
  }, [doRefresh, userName]);

  return {
    followers,
    loading,
    doRefresh,
  };
};
export default useGetUserFollowers;
