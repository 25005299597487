import React from "react";
import { Link } from "react-router-dom";
import routes from "../../constants/routesConstants";

const NotFound = () => {
  return (
    <section className="errorsec">
      <div className="container">
        <div className="errorCon">
          <h1 className="errorTitle ffc">
            <b>
              4<span>0</span>4
            </b>
          </h1>
          <h2 className="errorSubTitle ffc">Page Not Found</h2>
          <p>
            We're sorry the page you requested could not be found Please go
            back.
          </p>
          <Link to={routes.Home} className="btn btn-primary">
            Go Home
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
