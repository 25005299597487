import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logoImg from '../../assets/img/logo.svg';
import userImg from '../../assets/img/user.svg';
import routes from '../../constants/routesConstants';
import useConnectWallet from '../../hook/useConnectWallet';

const Navbar = () => {
  const { connect, loading, userData, logout } = useConnectWallet();
  const account = userData?.accountAddress;

  return (
    <div className="theme-navbar">
      <div className="container">
        <div className="row align-items-center">
          <div className="col">
            <div className="theme-logo">
              <Link to={routes.Marketplace}>
                <img src={logoImg} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-auto">
            {!account ? (
              <div className="theme-navbar-btn-wrap">
                <button className="btn btn-primary" onClick={() => connect()}>
                  <i className="icon-wallet"></i>{' '}
                  {loading ? 'Connecting...' : 'Connect Wallet'}
                </button>
              </div>
            ) : (
              <div className="theme-navbar-user">
                <div className="theme-navbar-user-info">
                  <div className="theme-navbar-user-image">
                    <img src={userData?.profileImage || userImg} alt="" />
                  </div>
                  <span className="theme-navbar-user-name">
                    {String(
                      userData?.userName ?? userData?.accountAddress
                    ).slice(0, 12)}
                  </span>
                </div>
                <ul className="theme-navbar-user-links">
                  <li>
                    <NavLink to={routes.Home}>
                      <i className="icon-activity"></i> Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={routes.Marketplace}>
                      <i className="icon-marketplace"></i> Marketplace
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={routes.Profile}>
                      <i className="icon-profile"></i> Profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={routes.Favourites}>
                      <i className="icon-favourites"></i> Favorites
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={routes.Collection}>
                      <i className="icon-collection"></i> My Collections
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={routes.StakingRewards}>
                      <i className="icon-collection"></i> Airdrop
                    </NavLink>
                  </li>
                  <li>
                    <a href="javascript:void(0)" onClick={() => logout()}>
                      <i className="icon-logout"></i> Logout
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
