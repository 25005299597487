import { useCallback, useContext, useEffect, useState } from 'react';
import API from '../api';
import AuthContext from '../context/authContext';

const PER_PAGE_RECORDS = 21;
const useGetMyFavNFTs = () => {
  const userData = useContext(AuthContext);

  const [collections, setCollections] = useState({});
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  const doRefresh = useCallback(async () => {
    if (!userData?.userData?.accountAddress) {
      return;
    }
    try {
      setLoading(true);
      const response = await API.get(
        `/user/fav-nfts?relations[]=user&relations[]=nft&relations[]=nft.user&skip=${
          skip * PER_PAGE_RECORDS
        }&take=${PER_PAGE_RECORDS}`
      );
      setCollections(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [skip, userData?.userData?.accountAddress]);

  useEffect(() => {
    doRefresh();
  }, [doRefresh, skip, userData?.userData?.accountAddress]);

  return { collections, loading, setSkip, doRefresh, skip };
};
export default useGetMyFavNFTs;
