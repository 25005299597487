import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import userImg from '../../../assets/img/user.svg';
import trophyImg from '../../../assets/img/svg/trophy.svg';
import Countdown, { zeroPad } from 'react-countdown';
import {
  marketPlaceNFTStatus,
  marketPlaceNFTType,
} from '../../../utils/etherConversionUtils';
import moment from 'moment/moment';

const categoryClasses = {
  Legendary: 'legendary',
  Rare: 'rare',
  'Super Rare': 'rare',
  Common: 'common',
};

const NFTCard = ({ record }) => {
  const [completed, setCompleted] = useState(false);

  let price =
    record?.marketPlace?.price ??
    record?.marketplace?.price ??
    record?.marketplace?.[0]?.price;
  const lastBid =
    record?.marketPlace?.lastBid ??
    record?.marketplace?.lastBid ??
    record?.marketplace?.[0]?.lastBid;

  const typeOfSale =
    record?.marketPlace?.typeOfSale ??
    record?.marketplace?.typeOfSale ??
    record?.marketplace?.[0]?.typeOfSale;

  const auctionEnds =
    record?.marketPlace?.auctionEnds ??
    record?.marketplace?.auctionEnds ??
    record?.marketplace?.[0]?.auctionEnds;

  if (
    marketPlaceNFTType?.[typeOfSale] === marketPlaceNFTStatus.ON_AUCTION &&
    lastBid !== "0"
  ) {
    price = lastBid;
  }

  let roleImage;

  if (record?.roles?.includes('Bowler')) {
    roleImage = `/images/Bowler.png`;
  }

  if (record?.roles?.includes('Batsman')) {
    roleImage = `/images/Batsman.png`;
  }

  if (record?.roles?.includes('Allrounder')) {
    roleImage = `/images/AllRounder.png`;
  }

  if (record?.roles?.includes('WK')) {
    roleImage = `/images/WicketKeeper.png`;
  }

  return (
    <div className="col-xl-4 col-sm-6  col-12">
      <div className="nft-card">
        <Link to={`/nft/${record?.uniqueEventId}`}>
          <div className="nft-card-header">
            {/* class options rare, common, superrare */}
            <div
              className={`nft-card-type ${categoryClasses?.[record?.category]}`}
            >
              {record?.category}
            </div>
            <div className="nft-card-id">ID #{record?.nftId}</div>
          </div>
          <div className="nft-card-body">
            <div className="nft-card-image">
              <img
                src={
                  process.env.REACT_APP_MODE === 'test'
                    ? `https://apix.cubixpro.io/nfts_test/assets/${record?.nftId}.jpg`
                    : `https://apix.cubixpro.io/nfts/assets/${record?.nftId}.jpg`
                }
                alt=""
              />
              {marketPlaceNFTType?.[typeOfSale] ===
              marketPlaceNFTStatus.ON_AUCTION ? (
                <div className="nft-card-timer">
                  <span className="nft-card-timer-text">
                    <i className="icon-timer"></i>
                    {moment(auctionEnds * 1000).isSameOrBefore(moment()) ||
                    completed ? (
                      <>Auction Completed</>
                    ) : (
                      <Countdown
                        date={new Date(auctionEnds * 1000)}
                        renderer={({ days, hours, minutes, seconds }) => {
                          return `${zeroPad(days)} : ${zeroPad(
                            hours
                          )} : ${zeroPad(minutes)} : ${zeroPad(seconds)}`;
                        }}
                        onComplete={() => {
                          setCompleted(true);
                        }}
                      />
                    )}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="nft-card-rank">
              <img src={trophyImg} alt="" />
              <span>RANK: {record?.no}</span>
            </div>
            <h6 className="nft-card-name">{record?.shortName}</h6>
          </div>
          <div className="nft-card-footer">
            <div className="nft-card-user">
              <div className="nft-card-user-image">
                <img src={record?.user?.profileImage || userImg} alt="" />
              </div>
              <div className="nft-card-user-detail">
                <span className="nft-card-user-type">Owner</span>
                <p className="nft-card-user-name">
                  {String(
                    record?.user?.userName ?? record?.user?.accountAddress
                  ).slice(0, 12)}
                </p>
              </div>
            </div>
            {price ? (
              <div className="nft-card-buy">
                <span className="nft-card-buy-label">Price</span>
                <p className="nft-card-buy-price">{price} CUBIX</p>
              </div>
            ) : null}
          </div>
        </Link>
      </div>
      <style jsx="true" global="true">
        {`
          .nft-card-image::before {
            background-image: url(${roleImage});
            background-size: 34px;
          }
        `}
      </style>
    </div>
  );
};

export default NFTCard;
