import { useContext, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Collection from './components/Collection';
import Favourites from './components/Favourites';
import Home from './components/Home';
import Marketplace from './components/Marketplace';
import Nft from './components/Nft';
import NotFound from './components/NotFound';
import Profile from './components/Profile';
// import Staking from './components/Staking';
import UserDetails from './components/UserDetails';
import Wrapper from './components/Wrapper';
import WrapperTwo from './components/Wrapper/WrapperTwo';
import WrapperThree from './components/Wrapper/WrapperThree';
import routes from './constants/routesConstants';
import AuthContext from './context/authContext';
// import StakingRewards from './components/StakingRewards';
import StakingRewardsV2 from './components/StakingRewardsV2';

const App = () => {
  const { logout } = useContext(AuthContext);
  useEffect(() => {
    window?.ethereum?.on?.('accountsChanged', function () {
      logout();
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Wrapper />}>
          <Route path={routes.Marketplace} element={<Marketplace />} exact />
          <Route path={routes.Nft} element={<Nft />} exact />
          <Route path="*" element={<NotFound />} replace />
        </Route>
        <Route element={<WrapperTwo />}>
          <Route path={routes.Favourites} element={<Favourites />} exact />
          <Route path={routes.Collection} element={<Collection />} exact />
          <Route path={routes.Profile} element={<Profile />} exact />
        </Route>
        <Route element={<Wrapper />}>
          <Route path={routes.UserDetails} element={<UserDetails />} exact />
          {/* <Route path={routes.Staking} element={<Staking />} exact /> */}
          <Route path={routes.StakingRewards} element={<StakingRewardsV2 />} exact />
        </Route>
        <Route element={<WrapperThree />}>
          <Route path={routes.Home} element={<Home />} exact />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
