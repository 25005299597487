import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import API from '../api';

const useGetMarketplaceMasters = () => {
  const called = useRef(null);
  const [masters, setMasters] = useState({});
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setMasters({});
  };
  const doRefresh = async () => {
    try {
      setLoading(true);
      let url = `/marketplace/nft-master`;

      const response = await API.get(url);
      setMasters(response?.data);
    } catch (error) {
      toast.error(error?.error?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    doRefresh();
  }, []);

  return {
    masters,
    loading,
    doRefresh,
    called,
    reset,
  };
};
export default useGetMarketplaceMasters;
