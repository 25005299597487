import React from 'react';

const Pagination = ({ totalPagesArray, skip, setSkip }) => {
  return (
    <div className="nft-cards mb-5">
      <nav aria-label="Page navigation" className="text-center">
        <ul className="pagination justify-content-center flex-wrap">
          {totalPagesArray?.length && skip !== 0 ? (
            <li className="page-item m-1" onClick={() => setSkip(skip - 1)}>
              <a
                className="page-link btn btn-outline-primary"
                href="javascript:void(0)"
              >
                Prev
              </a>
            </li>
          ) : null}

          {totalPagesArray?.map((page) => {
            return (
              <li className="page-item m-1" onClick={() => setSkip(page - 1)}>
                <a
                  className="page-link btn btn-outline-primary"
                  href="javascript:void(0)"
                >
                  {page}
                </a>
              </li>
            );
          })}

          {totalPagesArray?.length ? (
            <li className="page-item m-1" onClick={() => setSkip(skip + 1)}>
              <a
                className="page-link btn btn-outline-primary"
                href="javascript:void(0)"
              >
                Next
              </a>
            </li>
          ) : null}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
