import React, { useState, useCallback } from 'react';
import API from '../api';

const AuthContext = React.createContext({
  userData: {},
  isLoggedIn: false,
  login: async (data) => {},
  logout: () => {},
});

const retrieveStoredToken = () => {
  const storedUserData = JSON.parse(localStorage.getItem('userData'));
  return {
    storedUserData: storedUserData,
  };
};

export const AuthContextProvider = (props) => {
  const userDataOld = retrieveStoredToken();

  let initialUserData;
  if (userDataOld) {
    initialUserData = userDataOld.storedUserData;
  }

  const [userData, setUserData] = useState(initialUserData);

  const userIsLoggedIn = !!userData;

  const logoutHandler = useCallback(() => {
    setUserData();
    localStorage.removeItem('userData');
    localStorage.removeItem('x-token');
    window.location.replace('/marketplace');
  }, []);

  const loginHandler = async (data) => {
    try {
      if (data?.accountAddress) {
        const response = await API.post(
          `/user/register/${data?.accountAddress}`
        );
        const details = {
          ...data,
          ...response?.data,
        };
        setUserData(details);
        localStorage.setItem('x-token', details?.access_token);
        localStorage.setItem('userData', JSON.stringify(details));
        return true;
      }
    } catch (error) {
      console.log({ error });
    } finally {
    }
  };

  const contextValue = {
    userData: userData,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
