import AuthContext from '../context/authContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ethers = require('ethers');
const { useState, useContext } = require('react');

const useConnectWallet = () => {
  const navigate = useNavigate();
  const { login, userData, logout } = useContext(AuthContext);

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const reset = () => {
    login();
    setError();
    setLoading(false);
  };

  const afterLogin = () => {
    navigate('/profile', { replace: true });
  };

  const connect = async () => {
    try {
      reset();
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
      const signer = provider.getSigner();
      const accountAddress = await signer.getAddress();
      const result = await login({
        accountAddress,
        cb: afterLogin,
      });
      if (result) {
        toast.success('Logged in successfully');
        toast.info(
          'We have started sync process for NFTs if you are new user for cubixpro.world platform'
        );
        afterLogin();
      }
    } catch (error) {
      setError(error);
      console.log({ error });
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return { connect, reset, userData, error, loading, logout };
};

export default useConnectWallet;
