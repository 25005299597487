import { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import API from '../api';
import AuthContext from '../context/authContext';

const useToggleUserFollow = (doRefresh) => {
  const { userData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(false);

  const doToggle = useCallback(async (userId) => {
    try {
      if (!userData?.id) {
        throw new Error('Please login');
      }
      setLoading(true);
      const response = await API.post(`/user/toggle-follow`, {
        follows: userId,
      });

      setResponse(response?.data);
      if (response?.data) {
        toast.success('Followed successfully');
      } else {
        toast.success('Un followed successfully');
      }
      doRefresh(false);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, doToggle, response };
};
export default useToggleUserFollow;
