import AuthContext from '../context/authContext';
import { toast } from 'react-toastify';
import { getNMarketplaceContract } from '../constants/contractConstants';
import useApproveCubix from './useApproveCubix';
import { getMarketplaceDetails } from '../utils/etherConversionUtils';
import { utils } from 'ethers';

const { useState, useContext } = require('react');

const useBuyNFT = (doRefresh) => {
  const { userData, logout } = useContext(AuthContext);
  const { approve, loading: approving, checkAllowance } = useApproveCubix();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setError();
    setLoading(false);
  };

  const buyNftCall = async (marketplaceContract, tokenId) => {
    return new Promise(async (resolve) => {
      const boughtNFT = await marketplaceContract.functions.buyNFT(tokenId);
      boughtNFT.wait(3).then(() => {
        toast.success(
          `${tokenId} NFT bought successfully, will be reflected in 1 minute in your console`
        );
        resolve(true);
        doRefresh(false);
      });
    });
  };

  const buyNFT = async (tokenId) => {
    try {
      if (process.env.REACT_APP_TRADE_STARTS !== 'true') {
        throw new Error('Trade not started');
      }
      const allowance = await checkAllowance();
      reset();
      setLoading(true);
      const marketplaceContract = await getNMarketplaceContract();

      const marketplaceDetails = await getMarketplaceDetails(tokenId);

      // todo also check for isUSDT flag
      if (allowance < marketplaceDetails?.price) {
        // call approve and wait for tx confirmed
        const approved = await approve(
          utils.formatEther(String(marketplaceDetails?.price))
        );
        if (approved === true) {
          await buyNftCall(marketplaceContract, tokenId);
        }
      } else {
        // call buy NFT method of marketplace directly
        await buyNftCall(marketplaceContract, tokenId);
      }
    } catch (error) {
      setError(error);
      console.log({ error });
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    buyNFT,
    reset,
    userData,
    error,
    loading: loading || approving,
    logout,
  };
};

export default useBuyNFT;
