import React, { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ThemeContext from '../../context/themeContext';
import { motion } from 'framer-motion';
import stakePushImg from '../../assets/img/stake-push.png';
import stakePushLeftCardImg from '../../assets/img/stack-push-left-nft-card.png';
import stakePushRightCardImg from '../../assets/img/stack-push-right-nft-card.png';
import cubixPolygonImg from '../../assets/img/cubix-polygon.svg';
import { blockChainScan } from '../../constants/contractConstants';
import moment from 'moment';
import useGetNFTIds from '../../hook/useGetNFTIds';
import useStakeNFTs from '../../hook/useStakeNFTs';
import { formatEther } from '../../utils/etherConversionUtils';
import useClaimOldHoldingTokens from '../../hook/useClaimOldHoldingTokens';

const StakingRewardsV2 = () => {
  const location = useLocation();
  const { toggleNavbar, isNavbarOpen } = useContext(ThemeContext);

  const {
    doStakeNFTs,
    details,
    syncRewards,
    syncing,
    stackIds,
    loading,
    claimRewards,
    claiming,
    canClaim,
    claimRecords,
    lastSyncOn,
  } = useStakeNFTs();

  const {
    claimHoldingTokens,
    loading: claimingOldTokens,
    formateTokens,
  } = useClaimOldHoldingTokens();

  let canSyncRewards = moment(lastSyncOn)
    .add(24, 'hours')
    .isSameOrBefore(moment());

  if (!lastSyncOn) {
    canSyncRewards = stackIds?.length > 0;
  }

  const { tokenIds } = useGetNFTIds();

  useEffect(() => {
    if (isNavbarOpen) {
      toggleNavbar();
    }
  }, [location]);

  const stakePushRef = useRef(null);
  const stakePushLeftNftRef = useRef(null);
  const stakePushRightNftRef = useRef(null);

  const stakeNftsWrapMove = (e) => {
    stakePushRef.current.style.transform =
      'translate3d(' +
      (e.pageX * -1) / 30 +
      'px,' +
      (e.pageY * -1) / 60 +
      'px, 0)';

    stakePushLeftNftRef.current.style.transform =
      'translate3d(' +
      (e.pageX * -1) / 40 +
      'px,' +
      (e.pageY * -1) / 12 +
      'px, 0)';

    stakePushRightNftRef.current.style.transform =
      'translate3d(' +
      (e.pageX * -1) / 40 +
      'px,' +
      (e.pageY * -1) / 17 +
      'px, 0)';
  };

  return (
    <>
      <div className="stake-nft-sec common-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="stake-nft-con">
                <div className="sec-head">
                  <h1 className="sec-title">
                    <span>Welcome to the Cubix Airdrop.</span>
                  </h1>
                  <p>
                    If you are not playing with your NFTs, don't worry you will
                    still receive free $Cubix Airdrop by holding your NFT in
                    your same wallet for minimum 24 hours.
                  </p>
                  <p>
                    Play for fun or fierce competition and build up your
                    collections of NFT's.
                  </p>
                </div>
                {/* <a href="#" className="btn-2 btn-primary">
                  How To Stake?
                </a> */}
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="stake-nfts-wrap"
                onMouseMove={(e) => stakeNftsWrapMove(e)}
              >
                <motion.div
                  initial={{
                    opacity: 0,
                    y: 70,
                    x: -50,
                    scale: 0.7,
                    rotateX: 90,
                    rotateY: -40,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    y: 0,
                    scale: 1,
                    rotateX: 0,
                    rotateY: 0,
                  }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  ref={stakePushLeftNftRef}
                  className="stake-left-nft-card"
                >
                  <img src={stakePushLeftCardImg} alt="" />
                </motion.div>
                <motion.div
                  initial={{
                    opacity: 0,
                    y: 70,
                    x: 50,
                    scale: 0.7,
                    rotateX: 90,
                    rotateY: 40,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    y: 0,
                    scale: 1,
                    rotateX: 0,
                    rotateY: 0,
                  }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                  ref={stakePushRightNftRef}
                  className="stake-right-nft-card"
                >
                  <img src={stakePushRightCardImg} alt="" />
                </motion.div>
                <motion.div
                  ref={stakePushRef}
                  className="stake-nft-push"
                  initial={{ scale: 0.7, y: 30, opacity: 0.5 }}
                  whileInView={{ scale: 1, y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <img src={stakePushImg} alt="" />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="staking-profile-sec common-sec">
        <div className="container">
          <div className="sec-head text-center">
            <h2 className="sec-title">My Dashboard</h2>
          </div>

          {/* {formateTokens > 0 ? (
            <div className="container mb-5">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center">
                  <button
                    type="button"
                    className="btn-2 btn-primary w-75"
                    onClick={() => {
                      if (!claimingOldTokens) {
                        claimHoldingTokens(tokenIds?.records);
                      }
                    }}
                  >
                    {claimingOldTokens ? 'Please wait...' : `Claim Old Airdrop: ${formateTokens} CUBIX`}
                  </button>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          ) : null} */}
          {/* {parseInt(tokenIds?.total, 10) > parseInt(stackIds?.length, 10) ? (
            <div className="container mb-5">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center">
                  <button
                    type="button"
                    className="btn-2 btn-primary w-75"
                    onClick={() => {
                      if (!loading) {
                        doStakeNFTs(tokenIds?.records);
                      }
                    }}
                  >
                    {loading ? 'Please wait...' : 'Sync NFTs for Airdrop'}
                  </button>
                  <h5 className="text-center mt-2">
                    {stackIds?.length} out of {tokenIds?.total} NFTs are set for
                    airdrop rewards.
                  </h5>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          ) : null} */}

          {/* {canSyncRewards ? (
            <div className="container mb-5">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center">
                  <button
                    type="button"
                    className="btn-2 btn-primary w-75"
                    onClick={() => {
                      if (!syncing) {
                        syncRewards();
                      }
                    }}
                  >
                    {syncing ? 'Please wait...' : 'Sign in and sync rewards'}
                  </button>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          ) : (
            <p className="text-center">
              You don't have any tokens to claim as of now. After sync for
              airdrop, visit after sometime to claim rewards.
            </p>
          )} */}
          <p className="text-center" style={{ fontSize: 30 }}>
            To claim airdrop, install mobile app, please visit{' '}
            <a href="https://cubixpro.world" target="_blank">
              cubixprod.world
            </a>
          </p>
          <div className="staking-profile-inr">
            <div className="staking-profile-header">
              <div className="staking-profile-count-box flex-grow-1">
                <span className="staking-profile-count-label">Total NFT’s</span>
                <div className="staking-profile-count-text">
                  {tokenIds?.total}
                </div>
              </div>
              <div className="staking-profile-count-box flex-grow-1">
                <span className="staking-profile-count-label">
                  Total Earnings
                </span>
                <div className="staking-profile-count-box-row">
                  <div className="staking-profile-count-icon">
                    <img src={cubixPolygonImg} alt="" />
                  </div>
                  <div className="staking-profile-count-text">
                    {details?.balance}
                  </div>
                </div>
              </div>
              <div className="staking-profile-count-box flex-grow-1">
                <span className="staking-profile-count-label">
                  Total Claimed
                </span>
                <div className="staking-profile-count-box-row">
                  <div className="staking-profile-count-icon">
                    <img src={cubixPolygonImg} alt="" />
                  </div>
                  <div className="staking-profile-count-text">
                    {details?.rewardsReleased}
                  </div>
                </div>
              </div>

              {/* {canClaim > 0 ? (
                <div className="staking-profile-count-box flex-grow-1">
                  <div className="staking-profile-count-box-row">
                    <button
                      type="button"
                      className="btn-2 btn-primary"
                      onClick={() => {
                        claimRewards();
                      }}
                    >
                      {claiming ? 'Please wait...' : 'Claim'}
                    </button>
                  </div>
                </div>
              ) : null} */}
            </div>
          </div>

          <div className="table-responsive mt-5 mb-2">
            <div className="text-center">
              <h3 className="sec-title">Latest Claim History</h3>
            </div>
            <table className="table table-dark table-bordered">
              <thead>
                <tr>
                  <td>Transaction Id</td>
                  <td>Claimed Amount</td>
                  <td>Claimed On</td>
                </tr>
              </thead>
              <tbody>
                {claimRecords?.records?.length <= 0 ? (
                  <tr>
                    <td colSpan={3} className="text-center">
                      No Data found
                    </td>
                  </tr>
                ) : (
                  ''
                )}
                {claimRecords?.records?.map((record) => {
                  return (
                    <tr id={record?.id}>
                      <td>
                        <a
                          className="text-white text-decoration-underline"
                          target={'_blank'}
                          href={`${blockChainScan}${record?.txHash}`}
                        >
                          Check Transaction ({record?.txHash?.slice(0, 12)}...)
                        </a>
                      </td>
                      <td>
                        {record?.amount
                          ? formatEther(String(record?.amount))
                          : ''}{' '}
                        CUBIX
                      </td>
                      <td>
                        {moment(record?.createDateTime).format(
                          'DD-MM-YYYY hh:mm a'
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default StakingRewardsV2;
