import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import API from '../api';
import AuthContext from '../context/authContext';
import { getMarketplaceDetails } from '../utils/etherConversionUtils';

const useGetNFTDetails = (id) => {
  const userData = useContext(AuthContext);

  const called = useRef(false);
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const doRefresh = useCallback(
    async (showLoader = true) => {
      if (called.current) {
        return;
      }
      if (!userData?.userData?.accountAddress && !id) {
        return;
      }

      try {
        called.current = true;
        if (showLoader) {
          setLoading(true);
        }
        const response = await API.get(
          `/user/nfts?where[uniqueEventId]=${id}&extra[userId]=${userData?.userData?.id}&relations[]=user`
        );

        const nft = response?.data?.records?.[0];
        const metaData = await API.get(
          process.env.REACT_APP_MODE === 'test'
            ? `https://apix.cubixpro.io/nfts/${nft?.nftId}?test=true`
            : nft?.metaData
        );
        const marketplaceDetails = await getMarketplaceDetails(nft?.nftId);

        setDetails({
          ...nft,
          meta: metaData,
          marketplaceDetails,
        });
      } catch (error) {
      } finally {
        called.current = false;
        setLoading(false);
      }
    },
    [id, userData?.userData?.accountAddress]
  );

  useEffect(() => {
    doRefresh();
  }, [doRefresh, id, userData?.userData?.accountAddress]);

  return { details, loading, doRefresh, userData };
};
export default useGetNFTDetails;
