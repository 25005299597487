import AuthContext from '../context/authContext';
import { toast } from 'react-toastify';
import {
  CONTRACT_ADDRESS,
  getNFTContract,
} from '../constants/contractConstants';

const { useState, useContext, useEffect } = require('react');

const useApproveNFT = (tokenId) => {
  const { userData, logout } = useContext(AuthContext);

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [isApproved, setApproved] = useState(false);

  const checkApproved = async () => {
    try {
      if (process.env.REACT_APP_TRADE_STARTS !== 'true') {
        return;
      }
      reset();
      setLoading(true);
      const nftContract = await getNFTContract();
      const approved = await nftContract.functions.getApproved(tokenId);
      setApproved(
        String(approved?.[0]).toLowerCase() ===
          String(CONTRACT_ADDRESS.marketplace).toLowerCase()
      );
      // check approve
    } catch (error) {
      setError(error);
      console.log({ error });
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tokenId) {
      checkApproved(tokenId);
    }
  }, [tokenId]);
  const reset = () => {
    setError();
    setLoading(false);
  };

  const approve = async (tokenId) => {
    try {
      if (process.env.REACT_APP_TRADE_STARTS !== 'true') {
        throw new Error('Trade not started');
      }
      reset();
      setLoading(true);
      const nftContract = await getNFTContract();
      const approved = await nftContract.approve(
        CONTRACT_ADDRESS.marketplace,
        tokenId
      );

      approved.wait(3).then(() => {
        toast.success(`${tokenId} NFT approved, now you can proceed ahead.`);
        setLoading(false);
        if (tokenId) {
          checkApproved(tokenId);
        }
      });
    } catch (error) {
      setError(error);
      toast.error(
        error?.error?.data?.message || error?.message || 'Something went wrong!'
      );
      setLoading(false);
    }
  };

  return { approve, reset, userData, error, loading, logout, isApproved };
};

export default useApproveNFT;
