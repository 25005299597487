import { toast } from 'react-toastify';
import { useState } from 'react';
import API from '../api';

const useEmailSubscription = () => {
  const [error, setError] = useState();
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);

  const subscribe = async () => {
    try {
      if (!email) {
        toast.error('Please enter email');
        return;
      }
      setLoading(true);
      await API.post('/user/subscribe', { email });
      toast.success('You have subscribed to news letter successfully.');
    } catch (error) {
      setError(error);
      toast.error(error?.message);
    } finally {
      setLoading(false);
      setEmail('');
    }
  };

  return {
    error,
    loading,
    subscribe,
    email,
    setEmail,
  };
};

export default useEmailSubscription;
