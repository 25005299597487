import AuthContext from '../context/authContext';
import { toast } from 'react-toastify';
import { getNMarketplaceContract } from '../constants/contractConstants';

const { useState, useContext } = require('react');

const useRemoveFromSale = (doRefresh) => {
  const { userData, logout } = useContext(AuthContext);

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setError();
    setLoading(false);
  };

  const removeFromSale = (tokenId) => {
    return new Promise(async (resolve, reject) => {
      if (process.env.REACT_APP_TRADE_STARTS !== 'true') {
        reject('Trade not started');
      }
      try {
        reset();
        setLoading(true);
        const marketplaceContract = await getNMarketplaceContract();

        const removedMarketPlace =
          await marketplaceContract.functions.removeFromSale(tokenId);

        removedMarketPlace.wait(3).then(() => {
          toast.success(
            `${tokenId} removed from marketplace successfully, will be reflected in 1 minute in your console`
          );
          setLoading(false);
          resolve(true);
          doRefresh(false);
        });
      } catch (error) {
        setError(error);
        reject(error);
        toast.error(
          error?.error?.data?.message ||
            error?.message ||
            'Something went wrong!'
        );
        setLoading(false);
      }
    });
  };

  return { removeFromSale, reset, userData, error, loading, logout };
};

export default useRemoveFromSale;
