import AuthContext from '../context/authContext';
import { toast } from 'react-toastify';
import { getHoldingSettlementContract } from '../constants/contractConstants';

const { useState, useContext, useEffect } = require('react');

const useClaimOldHoldingTokens = () => {
  const { userData } = useContext(AuthContext);

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [formateTokens, setFormateTokens] = useState(0);

  const doRefresh = async (_address) => {
    try {
      const contract = await getHoldingSettlementContract();

      const tokens = await contract.functions._holdingTokensSattlement(
        _address
      );

      const formateTokens = parseInt(tokens?.toString(), 10);
      setFormateTokens(formateTokens);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userData?.accountAddress) {
      doRefresh(userData?.accountAddress);
    }
  }, [userData?.accountAddress]);

  const reset = () => {
    setError();
    setLoading(false);
  };

  const claimHoldingTokens = () => {
    return new Promise(async (resolve) => {
      try {
        if (process.env.REACT_APP_TRADE_STARTS !== 'true') {
          throw new Error('Trade not started');
        }
        reset();
        setLoading(true);
        const contract = await getHoldingSettlementContract();

        const claimed = await contract.functions.claim();

        claimed.wait(3).then(() => {
          toast.success('Cheers!!! You have claimed your tokens');
          setLoading(false);
          resolve(true);
          doRefresh?.(false);
          setLoading(false);
        });

        // check approve
      } catch (error) {
        setError(error);
        console.log({ error });
        toast.error(error?.message);
        setLoading(false);
      }
    });
  };

  return { claimHoldingTokens, reset, userData, error, loading, formateTokens };
};

export default useClaimOldHoldingTokens;
