import { toast } from 'react-toastify';
import { useState } from 'react';
import API from '../api';

const ethers = require('ethers');

const useUpdateProfile = (userData) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const updateProfile = async (values) => {
    try {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
      const signer = provider.getSigner();
      const accountAddress = await signer.getAddress();
      if (
        String(accountAddress).toLowerCase() ===
        String(userData?.accountAddress).toLowerCase()
      ) {
        await API.put('/user/me', values);
        toast.success('Profile Updated Successfully.');
      }
    } catch (error) {
      setError(error);
      console.log({ error });
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    error,
    loading,
    updateProfile,
  };
};

export default useUpdateProfile;
