import { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import API from '../api';
import AuthContext from '../context/authContext';

const useToggleFavNFT = (doRefresh) => {
  const userData = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(false);

  const doToggle = useCallback(
    async (nftId) => {
      if (!userData?.userData?.accountAddress && !nftId) {
        return;
      }
      try {
        setLoading(true);
        const response = await API.post(`/user/toggle-fav`, {
          nftId,
        });

        setResponse(response?.data);
        if (response?.data) {
          toast.success('Added to Favorites');
        } else {
          toast.success('Removed from Favorites');
        }
        doRefresh(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [userData?.userData?.accountAddress]
  );

  return { loading, doToggle, response };
};
export default useToggleFavNFT;
