import AuthContext from '../context/authContext';
import { toast } from 'react-toastify';
import { getNMarketplaceContract } from '../constants/contractConstants';

const ethers = require('ethers');
const { useState, useContext } = require('react');

const usePutOnMarketplace = (doRefresh) => {
  const { userData, logout } = useContext(AuthContext);

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setError();
    setLoading(false);
  };

  const putOnMarketplace = async (
    tokenId,
    price,
    typeOfSale,
    auctionEndsInDays,
    isUSDT,
    perDayRent
  ) => {
    try {
      if (process.env.REACT_APP_TRADE_STARTS !== 'true') {
        throw new Error('Trade not started');
      }
      reset();
      setLoading(true);
      const marketplaceContract = await getNMarketplaceContract();

      const addedOnMarketPlace =
        await marketplaceContract.functions.putOnMarketplace(
          tokenId,
          ethers.utils.parseUnits(String(price), 18),
          typeOfSale,
          auctionEndsInDays,
          isUSDT,
          perDayRent
        );

      addedOnMarketPlace?.wait(3).then(() => {
        toast.success(
          `${tokenId} NFT added to marketplace, will be reflected in 1 minute in your console`
        );
        setLoading(false);
        doRefresh(false);
      });
    } catch (error) {
      console.log({ error });
      setError(error);
      toast.error(error?.error?.data?.message || 'Something went wrong');
      setLoading(false);
    }
  };

  return { putOnMarketplace, reset, userData, error, loading, logout };
};

export default usePutOnMarketplace;
