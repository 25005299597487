import { useCallback, useEffect, useState } from 'react';
import API from '../api';

const PER_PAGE_RECORDS = 21;
const useGetUserNFTs = (userName) => {
  const [collections, setCollections] = useState({});
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  const doRefresh = useCallback(async () => {
    try {
      setLoading(true);
      let url = `/user/nfts?extra[userName]=${userName}&relations[]=user&skip=${
        skip * PER_PAGE_RECORDS
      }&take=${PER_PAGE_RECORDS}`;
      if (keyword !== '') {
        url += `&extra[keyword]=${keyword}`;
      }
      const response = await API.get(url);
      setCollections(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [skip, userName]);

  useEffect(() => {
    doRefresh();
  }, [doRefresh, skip, userName]);

  return {
    collections,
    loading,
    setSkip,
    doRefresh,
    skip,
    keyword,
    setKeyword,
    PER_PAGE_RECORDS,
  };
};
export default useGetUserNFTs;
