import { utils } from 'ethers';
import moment from 'moment';
import { toast } from 'react-toastify';
import API from '../api';
import { getStackingRewardContractV2 } from '../constants/contractConstants';
import AuthContext from '../context/authContext';
import { convertToNumberFromBigNumber } from '../utils/etherConversionUtils';

const { useState, useContext, useEffect } = require('react');

const useStakeNFTs = () => {
  const { userData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [syncing, setSyncing] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [lastSyncOn, setLastSyncOn] = useState();
  const [stackIds, setStackIds] = useState();
  const [canClaim, setCanClaim] = useState();
  const [claimRecords, setClaimRecords] = useState({});

  const doGetStakeNFTs = async () => {
    try {
      setLoading(true);
      const stackingRewardContract = await getStackingRewardContractV2();
      const stackDetails = await stackingRewardContract.stakers(
        userData?.accountAddress
      );

      const stackIds = await stackingRewardContract.getTokenIds(
        userData?.accountAddress
      );

      setStackIds(
        stackIds?.map((stackId) => convertToNumberFromBigNumber(stackId))
      );

      const lastSyncOn = await stackingRewardContract.lastSyncOn(
        userData?.accountAddress
      );

      const lastSyncOnTime = convertToNumberFromBigNumber(lastSyncOn);

      if (lastSyncOnTime > 0) {
        setLastSyncOn(
          moment(convertToNumberFromBigNumber(lastSyncOn) * 1000).format()
        );
      }

      const balance = convertToNumberFromBigNumber(
        utils.formatEther(String(stackDetails?.balance))
      );

      const data = {
        balance: utils.formatEther(String(stackDetails?.balance)),
        rewardsReleased: utils.formatEther(stackDetails?.rewardsReleased),
      };
      setDetails(data);
      setCanClaim(balance > 0);
      setLoading(false);
      getClaimRecords();
      return data;
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    doGetStakeNFTs();
  }, []);

  const doStakeNFTs = async (nfts) => {
    try {
      const tokenIds = nfts
        ?.map((record) => record?.nftId)
        .filter((id) => !stackIds?.some((i) => id === i))
        ?.slice(0, 100);

      setLoading(true);
      const stackingRewardContract = await getStackingRewardContractV2();

      const stacked = await stackingRewardContract.stake(tokenIds);

      stacked.wait(3).then(() => {
        toast.success(
          `You have allocated ${
            tokenIds >= 100 ? '100 of ' : ''
          } your NFTs successfully for Airdrop rewards.`,
          {
            autoClose: 60000,
          }
        );
        setLoading(false);
        doGetStakeNFTs();
      });
    } catch (error) {
      toast.error(error?.message);
      setLoading(false);
    }
  };

  const syncRewards = async () => {
    try {
      setSyncing(true);
      const stackingRewardContract = await getStackingRewardContractV2();

      const syncRewarded = await stackingRewardContract.syncRewards(
        userData?.accountAddress
      );

      syncRewarded.wait(3).then(() => {
        toast.success(`Sync successfully done.`, {
          autoClose: 60000,
        });
        setSyncing(false);
        doGetStakeNFTs();
      });
    } catch (error) {
      toast.error(error?.message);
      setSyncing(false);
    }
  };

  const claimRewards = async () => {
    try {
      setClaiming(true);
      const stackingRewardContract = await getStackingRewardContractV2();

      const syncRewarded = await stackingRewardContract.claim(
        userData?.accountAddress
      );

      syncRewarded.wait(3).then(() => {
        toast.success(`You have claimed rewards successfully.`, {
          autoClose: 60000,
        });
        setClaiming(false);
        doGetStakeNFTs();
      });
    } catch (error) {
      toast.error(error?.message);
      setClaiming(false);
    }
  };

  const getClaimRecords = async () => {
    const claimRecords = await API.get(
      `/marketplace/NFTRewardsClaims?where[accountAddress]=${String(
        userData?.accountAddress
      ).toLowerCase()}&order[createDateTime]=DESC&skip=0&take=7`
    );
    setClaimRecords(claimRecords?.data);
  };

  return {
    doStakeNFTs,
    doGetStakeNFTs,
    loading,
    details,
    syncRewards,
    syncing,
    lastSyncOn,
    stackIds,
    claimRewards,
    claiming,
    canClaim,
    claimRecords,
  };
};

export default useStakeNFTs;
