import React, { useContext } from 'react';
import moment from 'moment';
import userImgLg from '../../assets/img/userLg.svg';
import AuthContext from '../../context/authContext';
import useGetMyFavNFTs from '../../hook/useGetMyFavNFTs';
import NFTCard from '../Shared/NFT/NFTCard';

const Favourites = () => {
  const { userData } = useContext(AuthContext);

  const { collections, loading } = useGetMyFavNFTs();
  const records = collections?.data?.records;

  return (
    <div className="profile-wrap-inner">
      <div className="profile-wrap-user">
        <div className="profile-wrap-image">
          <img src={userData?.profileImage || userImgLg} alt="" />
        </div>
        <div className="profile-wrap-user-info">
          <h5 className="profile-wrap-user-name">
            {String(userData?.userName ?? userData?.accountAddress).slice(
              0,
              12
            )}
          </h5>
          <span className="profile-wrap-user-join">
            Joined {moment(userData?.createDateTime).format('MMM YYYY')}
          </span>
        </div>
        <p className="profile-wrap-wallet-id">{userData?.accountAddress}</p>
      </div>
      {records?.length <= 0 && !loading ? (
        <p className="mb-5 mt-5 text-center">
          You don't have any favorite NFTs yet.
        </p>
      ) : null}
      {loading ? <div className="loader">Loading...</div> : null}
      <div className="nft-cards">
        <div className="row">
          {records?.map((record) => {
            return <NFTCard record={record?.nft} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Favourites;
