import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import API from '../api';
import { marketPlaceNFTStatus } from '../utils/etherConversionUtils';

const PER_PAGE_RECORDS = 20;
const useGetMarketplaceOffers = (marketPlaceId, typeOfSale) => {
  const called = useRef(false);
  const [offers, setOffers] = useState({});
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  const doRefresh = useCallback(async () => {
    try {
      if (called.current) {
        return;
      }
      if (typeOfSale !== marketPlaceNFTStatus.ON_SALE) {
        return;
      }
      called.current = true;
      setLoading(true);
      let url = `/marketplace/MarketplaceNftOffersRecords?relations[]=buyerUser&where[_id]=${marketPlaceId}&skip=${
        skip * PER_PAGE_RECORDS
      }&take=${PER_PAGE_RECORDS}&order[createDateTime]=DESC`;
      const response = await API.get(url);
      setOffers(response);
    } catch (error) {
      toast.error(error?.error?.data?.message || 'Something went wrong');
    } finally {
      called.current = false;
      setLoading(false);
    }
  }, [skip, marketPlaceId]);

  useEffect(() => {
    doRefresh();
  }, [doRefresh, skip, marketPlaceId]);

  return {
    offers,
    loading,
    setSkip,
    doRefresh,
    skip,
    keyword,
    setKeyword,
    PER_PAGE_RECORDS,
  };
};
export default useGetMarketplaceOffers;
